<template>
    <div id="main">
        <label for="message">我要选的号码</label>
        <textarea id="message" v-model="bet" rows="4" placeholder="在这时输入快译规则..."></textarea>
        <button @click="betPost">生成号码</button>
        <div v-show="total" class="total">{{  total }}</div>

        <span class="text-white"
                v-for="(item, index) in order"
                :key="index"
                style='font-family: "Lucida Console", Consolas, "Courier New", Courier, monospace;'
            >
                {{ item.num }}  &nbsp;&nbsp;&nbsp; <br v-if="7==index%8" />
        </span>

    </div>
</template>
<script>
// npm install axios --save
import axios from 'axios'
export default {
    data() {
        return {
            bet: '',
            total: '',
            order: []
        }
    },
    methods: {
        betPost()
        {
            this.total = ''
            this.order = []
            axios.post(
                '/api', 
                {
                    msg: this.bet
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        //   'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }
            )
            .then((response) =>  {
                
                if (0===response.data.code) {
                    
                    this.total = String(response.data.data.total) + ' 注'
                    this.order = response.data.data.order
                    console.log(this.order)
                    // console.log(response.data.data.total)
                }
                
            })
            .catch(function (error) {
                console.log(error)
            })
        },
    }
}
</script>
<style lang="scss" scoped>
    #main {
        /** 在 scss 中调用 tailwindcss */
        @apply p-2 m-2 bg-slate-900;
    }
    label {
        @apply block mb-2 text-sm font-medium text-gray-900 dark:text-white;
    }
    textarea {
        @apply block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
    }
    button {
        @apply mt-4;
        @apply px-4;
        @apply py-2;
        @apply text-xl;
        @apply rounded;
        
        @apply text-white;
        @apply font-medium;
        @apply ring-black;
        @apply ring-opacity-10;
        @apply focus:ring;
        // 从这个色渐变到另一个色
        @apply from-orange-500; 
        @apply to-lime-500;
        @apply bg-gradient-to-r;
    }
    .total {
        @apply text-white;
    }
</style>
