<template>
    <div id="main">
        <label for="message">我要选的号码</label>
        <textarea id="message" v-model="bet" rows="4" placeholder="在这时输入快译规则..."></textarea>
        <button @click="betPost">生成号码</button>
        <div v-show="total" class="total">{{  total }}</div>

        <span class="text-white"
                v-for="(item, index) in order"
                :key="index"
                style='font-family: "Lucida Console", Consolas, "Courier New", Courier, monospace;'
            >
                {{ item.num }}  &nbsp;&nbsp;&nbsp; <br v-if="7==index%8" />
        </span>

    </div>
</template>
<script>
// npm install axios --save
import axios from 'axios'
export default {
    data() {
        return {
            bet: '',
            total: '',
            order: []
        }
    },
    methods: {
        betPost()
        {
            this.total = ''
            this.order = []
            axios.post(
                '/api', 
                {
                    msg: this.bet
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        //   'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }
            )
            .then((response) =>  {
                
                if (0===response.data.code) {
                    
                    this.total = String(response.data.data.total) + ' 注'
                    this.order = response.data.data.order
                    console.log(this.order)
                    // console.log(response.data.data.total)
                }
                
            })
            .catch(function (error) {
                console.log(error)
            })
        },
    }
}
</script>
<style lang="scss" scoped>

</style>
