// 进口路由
import { createRouter, createWebHistory } from "vue-router"

import betFast from "@/pages/bet/fast"
import mobileHome from "@/pages/mobile/HomeIndex"
import computerHome from "@/pages/computer/HomeIndex"

const routes = [
    {
        name: 'betFast',
        // 路由的 URL 字串
        path: '/bet/fast',
        component: betFast,
        // meta: {
        //     title: '快译'
        // }
    },
    // 首页-电脑端
    {
        name: 'home',
        path: '/',
        component: computerHome,
        // meta: {
        //     title: '快译'
        // }
    },
    // 首页-电脑端
    {
        name: 'computerHome',
        path: '/c/home',
        component: computerHome,
        // meta: {
        //     title: '快译'
        // }
    },
    // 首页-手机端
    {
        name: 'mobileHome',
        path: '/m/home',
        component: mobileHome,
        // meta: {
        //     title: '快译'
        // }
    },
]


const router = new createRouter({
    history: createWebHistory(),
    routes
})

// 在路由跳转前执行的操作
router.beforeEach((to, from, next) => {
    // 在这里进行权限验证、登录判断等操作
    // console.log('在这里进行权限验证、登录判断等操作')
    // console.log('跳转到:', to.path)
    // console.log('来自:', from.path)
    // console.log('token:', localStorage.getItem('token') )

    // if (to.path !== '/' && !localStorage.getItem('token')) {
    //     next('/')
    // } else {
    //     next() // 允许路由跳转 跳转到下一个路由
    // }
    next() // 允许路由跳转 跳转到下一个路由
})

// 在路由跳转后执行的操作
router.afterEach(() => {
    // 跳转后的操作
    // console.log('路由跳转完成')
})

// 在导航被确认之前，所有组件内守卫和异步路由组件被解析之后调用。
router.beforeResolve((to, from, next) => {
    // 在导航被确认之前的操作
    // console.log('在导航被确认之前的操作')
    next()  // 允许路由跳转 跳转到下一个路由
})

export default router