<template>
    <div id="main">
        ssss
        ssss
        ssss
        ssss
        ssss
        ssss
    </div>
</template>
<script>
// npm install axios --save
import axios from 'axios'
export default {
    data() {
        return {
            bet: '',
            total: '',
            order: []
        }
    },
    methods: {
        betPost()
        {
            this.total = ''
            this.order = []
            axios.post(
                '/api', 
                {
                    msg: this.bet
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        //   'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }
            )
            .then((response) =>  {
                
                if (0===response.data.code) {
                    
                    this.total = String(response.data.data.total) + ' 注'
                    this.order = response.data.data.order
                    console.log(this.order)
                    // console.log(response.data.data.total)
                }
                
            })
            .catch(function (error) {
                console.log(error)
            })
        },
    }
}
</script>
<style lang="scss" scoped>

</style>
